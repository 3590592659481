import React, { lazy } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
const RootLayout = lazy(() => import('../layout/RootLayout'));
const VerifyCode = lazy(() => import('../pages/Kiosk/VerifyCode'));
const ConformOrder = lazy(() => import('../pages/Kiosk/ConformOrder'));
const OpenBin = lazy(() => import('../pages/Kiosk/OpenBin'));
const Feedback = lazy(() => import('../pages/Kiosk/Feedback'));
const SecureBadgeLogin = lazy(() => import('../pages/Admin/SecureBadgeLogin'));
const AdminLogin = lazy(() => import('../pages/Admin/AdminLogin'));
const AdminPanel = lazy(() => import('../pages/Admin/AdminPanel'));
const AdminStock = lazy(() => import('../pages/Admin/AdminStock'));
const AdminVerify = lazy(() => import('../pages/Admin/AdminVerify'));
const AdminRemove = lazy(() => import('../pages/Admin/AdminRemove'));
const AdminSetting = lazy(() => import('../pages/Admin/AdminSetting'));
const AdminMaintenance = lazy(() => import('../pages/Admin/AdminMaintenance'));
const AdminForgotPassword = lazy(() => import('../pages/Admin/AdminForgotPassword'));
const InstallMode = lazy(() => import('../pages/InstallMode/InstallMode'));
const HardwareTest = lazy(() => import('../pages/InstallMode/HardwareTest'));
const InternetTest = lazy(() => import('../pages/InstallMode/InternetTest'));
const Results = lazy(() => import('../pages/InstallMode/Results'));

const is_install_mode = JSON.parse(localStorage.getItem('is_install_mode'));
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      {[false, 'false', undefined, null].includes(is_install_mode) ? (<Route element={<RootLayout />}>
        <Route path="/" element={<VerifyCode />} />
        <Route path="/conformOrder" element={<ConformOrder />} />
        <Route path="/openBin" element={<OpenBin />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/securebadge" element={<SecureBadgeLogin />} />
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route path="/adminPanel" element={<AdminPanel />} />
        <Route path="/adminStock" element={<AdminStock />} />
        <Route path="/adminVerify" element={<AdminVerify />} />
        <Route path="/adminRemove" element={<AdminRemove />} />
        <Route path="/adminMaintenance" element={<AdminMaintenance />} />
        <Route path="/adminSetting" element={<AdminSetting />} />
        <Route path="/forgetPassword" element={<AdminForgotPassword />} />
      </Route>) : (
        <Route path='/' element={<InstallMode />}></Route>
      )}
      <Route path="/hardwaretest" element={<HardwareTest />} />
      <Route path="/internettest" element={<InternetTest />} />
      <Route path="/results" element={<Results />} />
    </Route>,
  ),
);
export default router;