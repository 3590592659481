export default {
  AA1: '0DAA0049F2',
  AA2: '0DAA0050F2',
  AA3: '0DAA0051F2',
  AA4: '0DAA0052F2',
  AA5: '0DAA0053F2',
  AA6: '0DAA0054F2',
  AA7: '0DAA0055F2',
  AA8: '0DAA0056F2',
  AA9: '0DAA0057F2',
  AA10: '0DAA0058F2',
  AA11: '0DAA0059F2',
  AA12: '0DAA0060F2',
  BB1: '0DAA0037F2',
  BB2: '0DAA0038F2',
  BB3: '0DAA0039F2',
  BB4: '0DAA0040F2',
  BB5: '0DAA0041F2',
  BB6: '0DAA0042F2',
  BB7: '0DAA0043F2',
  BB8: '0DAA0044F2',
  BB9: '0DAA0045F2',
  BB10: '0DAA0046F2',
  BB11: '0DAA0047F2',
  BB12: '0DAA0048F2',
  CC1: '0DAA0025F2',
  CC2: '0DAA0026F2',
  CC3: '0DAA0027F2',
  CC4: '0DAA0028F2',
  CC5: '0DAA0029F2',
  CC6: '0DAA0030F2',
  CC7: '0DAA0031F2',
  CC8: '0DAA0032F2',
  CC9: '0DAA0033F2',
  CC10: '0DAA0034F2',
  CC11: '0DAA0035F2',
  CC12: '0DAA0036F2',
  DD1: '0DAA0065F2',
  DD2: '0DAA0066F2',
  DD3: '0DAA0067F2',
  DD4: '0DAA0068F2',
  EE1: '0DAA0061F2',
  EE2: '0DAA0062F2',
  EE3: '0DAA0063F2',
  EE4: '0DAA0064F2',
  D1: '0DAA0025F2',
  D2: '0DAA0026F2',
  D3: '0DAA0027F2',
  D4: '0DAA0028F2',
  E1: '0DAA0029F2',
  E2: '0DAA0030F2',
  E3: '0DAA0031F2',
  E4: '0DAA0032F2',
  D9: '0DAA0033F2',
  D10: '0DAA0034F2',
  D11: '0DAA0035F2',
  D12: '0DAA0036F2',
  E5: '0DAA0041F2',
  E6: '0DAA0042F2',
  E7: '0DAA0043F2',
  E8: '0DAA0044F2',
  E9: '0DAA0045F2',
  E10: '0DAA0046F2',
  E11: '0DAA0047F2',
  E12: '0DAA0048F2',
  F1: '0DAA0049F2',
  F2: '0DAA0050F2',
  F3: '0DAA0051F2',
  F4: '0DAA0052F2',
  F5: '0DAA0053F2',
  F6: '0DAA0054F2',
  F7: '0DAA0055F2',
  F8: '0DAA0056F2',
  F9: '0DAA0057F2',
  F10: '0DAA0058F2',
  F11: '0DAA0059F2',
  F12: '0DAA0060F2',
  I1: '0D550061F2',
  I2: '0D550062F2',
  I3: '0D550063F2',
  I4: '0D550064F2',
  J1: '0D550065F2',
  J2: '0D550066F2',
  J3: '0D550067F2',
  J4: '0D550068F2',
};
