/* eslint-disable brace-style */
const syncMapping = (bin_ID) => {
  let binID = bin_ID;
  if (Array.isArray(binID)) {
    const multipleBinsToOpen = [];
    for (let i = 0; i < binID.length; i += 1) {
      let singleBinID = binID[i];
      const bins = singleBinID.split('');
      if (
        singleBinID
        && singleBinID.includes('A')
        && Number.isInteger(parseInt(binID[i][1], 10))
      ) {
        [singleBinID] = bins;
        multipleBinsToOpen.push(
          bins.length === 2
            ? (singleBinID += parseInt(bins[1], 10) - 6)
            : (singleBinID += parseInt(bins[1] + bins[2], 10) - 6),
        );
      } else if (
        singleBinID
        && singleBinID.includes('B')
        && Number.isInteger(parseInt(binID[i][1], 10))
      ) {
        singleBinID = bins[0].replace('B', 'A');
        multipleBinsToOpen.push(
          bins.length === 2
            ? (singleBinID += parseInt(bins[1], 10))
            : (singleBinID += parseInt(bins[1] + bins[2], 10)),
        );
      } else if (
        singleBinID
        && singleBinID.includes('C')
        && Number.isInteger(parseInt(binID[i][1], 10))
      ) {
        if (singleBinID && singleBinID.length === 2 && singleBinID[1] <= 5) {
          singleBinID = bins[0].replace('C', 'B');
          multipleBinsToOpen.push((singleBinID += parseInt(bins[1], 10) + 7));
        } else {
          [singleBinID] = bins;
          multipleBinsToOpen.push(
            bins.length === 2
              ? (singleBinID += parseInt(bins[1], 10) + 2)
              : (singleBinID += parseInt(bins[1] + bins[2], 10) + 2),
          );
        }
      } else if (
        !binID[i].includes('A')
        || !binID[i].includes('B')
        || !binID[i].includes('C')
      ) {
        multipleBinsToOpen.push(binID[i]);
      }
    }
    return multipleBinsToOpen;
  }
  const bins = binID && binID.split('');
  if (binID && binID.includes('A') && Number.isInteger(parseInt(binID[1], 10))) {
    [binID] = bins;
    if (bins.length === 2) {
      binID += parseInt(bins[1], 10) - 6;
    } else {
      binID += parseInt(bins[1] + bins[2], 10) - 6;
    }
    return binID;
  } if (
    binID
    && binID.includes('B')
    && Number.isInteger(parseInt(binID[1], 10))
  ) {
    binID = bins[0].replace('B', 'A');
    if (bins.length === 2) {
      binID += parseInt(bins[1], 10);
    } else {
      binID += parseInt(bins[1] + bins[2], 10);
    }
    return binID;
  } if (
    binID
    && binID.includes('C')
    && Number.isInteger(parseInt(binID[1], 10))
  ) {
    if (binID && binID.length === 2 && binID[1] <= 5) {
      binID = bins[0].replace('C', 'B');
      (binID += parseInt(bins[1], 10) + 7);
      return binID;
    }
    [binID] = bins;
    if (bins.length === 2) {
      (binID += parseInt(bins[1], 10) + 2);
    } else {
      (binID += parseInt(bins[1] + bins[2], 10) + 2);
    }
    return binID;
  }

  return binID;
};

const reverseSyncMapping = {
  A1: 'A7',
  A2: 'A8',
  A3: 'A9',
  A4: 'A10',
  A5: 'A11',
  A6: 'A12',
  A7: 'B7',
  A8: 'B8',
  A9: 'B9',
  A10: 'B10',
  A11: 'B11',
  A12: 'B12',
  B8: 'C1',
  B9: 'C2',
  B10: 'C3',
  B11: 'C4',
  B12: 'C5',
  C8: 'C6',
  C9: 'C7',
  C10: 'C8',
  C11: 'C9',
  C12: 'C10',
  C13: 'C11',
  C14: 'C12',
};

export { syncMapping, reverseSyncMapping };
