const BOX_TYPE = {
  MAIN_46: 'd5e3af71-ab56-44c4-bf4d-de7b53e47f38',
  L_60: '415f008a-2913-49ea-9c62-e830f2c07008',
  COOL_8: 'fdfe7cf1-d266-4e1a-b26d-87423ce2b56b',
  HD_36: '68534189-6ac4-4fdb-ae16-374d7870fd92',
  M_22: 'eb5e33fc-b744-46fb-9ee6-802c602977c1',
  FASTLANE: 'e9da3c38-b838-4054-8b74-dcfb7d2713d8',
  M_24: '9fa0079a-3d98-4942-88c8-3f6537d68e48',
};

export default BOX_TYPE;
